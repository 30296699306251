import React, { lazy } from 'react';
import { GatewayConfigs } from '@chronosphereio/core';
import { FeatureFlags, HeaderState } from '@/context';

export type RouteObject = {
  route: string;
  subRoutes?: string[];
  Component: React.LazyExoticComponent<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((props: any) => JSX.Element | null) | React.NamedExoticComponent
  >;
  header: HeaderState;
  hideRouteFn?: (args: {
    featureFlags: FeatureFlags;
    gatewayConfigs: GatewayConfigs;
    isInternalUser?: boolean;
  }) => boolean;
  wildcard?: boolean;
};

export type MappedRoutes<T> = { [k in keyof T]: RouteObject };

// Home route components
const GlobalHome = lazy(async () => {
  const { GlobalHomepage } = await import('@/views/homepages/GlobalHomepage');
  return { default: GlobalHomepage };
});
const TeamHome = lazy(async () => {
  const { TeamHomepage } = await import('@/views/homepages/team/TeamHomepage');
  return { default: TeamHomepage };
});
const CollectionHome = lazy(async () => {
  const { CollectionHomepage } = await import('@/views/homepages/collection/CollectionHomepage');
  return { default: CollectionHomepage };
});
const ServicesHome = lazy(async () => {
  const { ServicesList } = await import('@/views/services/ServicesList');
  return { default: ServicesList };
});
const LicenseHome = lazy(async () => {
  const { LicenseOverview } = await import('@/views/homepages/license/LicenseOverview');
  return { default: LicenseOverview };
});

// Short URL route components
const ShortUrlLoader = lazy(async () => {
  const { ShortUrlLoader } = await import('@/views/short-url/ShortUrlLoader');
  return { default: ShortUrlLoader };
});

// Services route components
const ServiceOverviewPage = lazy(async () => {
  const { ServiceOverviewPage } = await import('@/views/homepages/service/ServiceOverviewPage');
  return { default: ServiceOverviewPage };
});
const ServiceComponentMoreDetailsPage = lazy(async () => {
  const { ServiceComponentDetailsView } = await import('@/views/homepages/service/ServiceComponentDetailsView');
  return { default: ServiceComponentDetailsView };
});
const ServiceComponentSandbox = lazy(async () => {
  const { ServiceComponentSandbox } = await import('@/views/homepages/service/ServiceComponentSandbox');
  return { default: ServiceComponentSandbox };
});

// SLO route components
const SloList = lazy(async () => {
  const { SloList } = await import('@/views/homepages/slo/SloList');
  return { default: SloList };
});
const SloDetails = lazy(async () => {
  const { SloDetails } = await import('@/views/homepages/slo/SloDetails');
  return { default: SloDetails };
});

// Dashboard route components
const Dashboards = lazy(async () => {
  const { DashboardsTable } = await import('@/views/dashboards/list-page/DashboardsTable');
  return { default: DashboardsTable };
});
const Dashboard = lazy(async () => {
  const { Dashboard } = await import('@/views/dashboards/Dashboard');
  return { default: Dashboard };
});

// Alerting route components
const Monitors = lazy(async () => {
  const { Monitors } = await import('@/views/alerts/monitors/MonitorsList');
  return { default: Monitors };
});
const Monitor = lazy(async () => {
  const { Monitor } = await import('@/views/alerts/monitors/Monitor');
  return { default: Monitor };
});
const MutingRules = lazy(async () => {
  const { MutingRulesContainer } = await import('@/views/alerts/muting-rules/MutingRulesContainer');
  return { default: MutingRulesContainer };
});
const Notifiers = lazy(async () => {
  const { Notifiers } = await import('@/views/notifiers/Notifiers');
  return { default: Notifiers };
});
const NotificationPolicies = lazy(async () => {
  const { NotificationPolicies } = await import('@/views/notification-policies/NotificationPolicies');
  return { default: NotificationPolicies };
});

// Exploring route components
const MetricsExplorer = lazy(async () => {
  const { MetricsExplorer } = await import('@/views/metrics/MetricsExplorer');
  return { default: MetricsExplorer };
});
const ExplorerBeta = lazy(async () => {
  const { Explorer } = await import('@/components/explorer');
  return { default: Explorer };
});

const MetricsAnalyzer = lazy(async () => {
  const { MetricsAnalyzer } = await import('@/views/metrics-analyzer/traffic-analyzer/MetricsAnalyzer');
  return { default: MetricsAnalyzer };
});

const MetricsAnalyzerRedirect = lazy(async () => {
  const { AnalyzerRedirect } = await import('@/views/metrics-analyzer/MetricsAnalyzerRedirect');
  return { default: AnalyzerRedirect };
});
const UsageAnalyzer = lazy(async () => {
  const { UsageAnalyzer } = await import('@/views/metrics-analyzer/usage-analyzer/UsageAnalyzer');
  return { default: UsageAnalyzer };
});
const Traces = lazy(async () => {
  const { Traces } = await import('@/views/traces/Traces');
  return { default: Traces };
});
const TracesDdx = lazy(async () => {
  const { TracesDdx } = await import('@/views/traces/Traces');
  return { default: TracesDdx };
});
const TracesTopologyView = lazy(async () => {
  const { TracesTopologyView } = await import('@/views/traces/Traces');
  return { default: TracesTopologyView };
});
const TraceAnalyzer = lazy(async () => {
  const { TraceAnalyzer } = await import('@/views/traces/trace-analyzer/TraceAnalyzer');
  return { default: TraceAnalyzer };
});
const TraceMetrics = lazy(async () => {
  const { TraceMetricRules } = await import('@/views/traces/trace-metric-rules/TraceMetricRules');
  return { default: TraceMetricRules };
});
const TraceDetails = lazy(async () => {
  const { TraceDetails } = await import('@/views/traces/trace-details/TraceDetails');
  return { default: TraceDetails };
});
const TraceUserJourneys = lazy(async () => {
  const { UserJourneys: TraceUserJourneys } = await import('@/views/traces/trace-user-journeys/UserJourneys');
  return { default: TraceUserJourneys };
});
const Timeline = lazy(async () => {
  const { Timeline } = await import('@/views/timeline/Timeline');
  return { default: Timeline };
});

// Shaping route components
const DropRules = lazy(async () => {
  const { IngestionPolicies } = await import('@/views/ingestion-policies/IngestionPolicies');
  return { default: IngestionPolicies };
});
const IngestConfiguration = lazy(async () => {
  const { IngestConfiguration } = await import('@/views/IngestConfiguration');
  return { default: IngestConfiguration };
});
const RecordingRules = lazy(async () => {
  const { RecordingRules } = await import('@/views/recording-rules/RecordingRules');
  return { default: RecordingRules };
});
const AggregationRules = lazy(async () => {
  const { AggregationRulesWrapper } = await import('@/views/aggregation-rules/AggregationRulesWrapper');
  return { default: AggregationRulesWrapper };
});
const TraceHeadSamplingRules = lazy(async () => {
  const { HeadSamplingRules } = await import('@/views/traces/trace-control-plane/head-sampling/HeadSamplingRules');
  return { default: HeadSamplingRules };
});
const TraceTailSamplingRules = lazy(async () => {
  const { TailSamplingRules } = await import('@/views/traces/trace-control-plane/tail-sampling/TailSamplingRules');
  return { default: TailSamplingRules };
});
const TraceDatasetsOverview = lazy(async () => {
  const { DatasetsOverview } = await import('@/views/traces/trace-control-plane/datasets/overview/DatasetsOverview');
  return { default: DatasetsOverview };
});
const TraceDatasetsDetail = lazy(async () => {
  const { DatasetsDetail } = await import('@/views/traces/trace-control-plane/datasets/detail/DatasetsDetail');
  return { default: DatasetsDetail };
});
const TraceBehaviors = lazy(async () => {
  const { BehaviorsOverview } = await import(
    '@/views/traces/trace-control-plane/behaviors/behaviors-overview/BehaviorsOverview'
  );
  return { default: BehaviorsOverview };
});
const TraceBehaviorDetails = lazy(async () => {
  const { BehaviorDetails } = await import(
    '@/views/traces/trace-control-plane/behaviors/behavior-details/BehaviorDetails'
  );
  return { default: BehaviorDetails };
});

// Managing route components
const AuditLogs = lazy(async () => {
  const { AuditLogs } = await import('@/views/audit-logs/AuditLogs');
  return { default: AuditLogs };
});
const CollectionsTable = lazy(async () => {
  const { CollectionsTable } = await import('@/views/collections/CollectionsTable');
  return { default: CollectionsTable };
});
const DerivedLabelsRedirect = lazy(async () => {
  const { DerivedLabelsRedirect } = await import('@/views/derived-labels/DerivedLabelsRedirect');
  return { default: DerivedLabelsRedirect };
});
const PublishedDerivedLabels = lazy(async () => {
  const { PublishedLabels } = await import('@/views/derived-labels/published-labels/PublishedLabels');
  return { default: PublishedLabels };
});
const DraftDerivedLabels = lazy(async () => {
  const { DraftLabels } = await import('@/views/derived-labels/draft-labels/DraftLabels');
  return { default: DraftLabels };
});
const MetricsAllocations = lazy(async () => {
  const { MetricsAllocations } = await import('@/views/allocations/MetricsAllocations');
  return { default: MetricsAllocations };
});
const MetricsQuotas = lazy(async () => {
  const { MetricsQuotas } = await import('@/views/quotas/MetricsQuotas');
  return { default: MetricsQuotas };
});
const QueryAnalyzer = lazy(async () => {
  const { QueryAnalyzer } = await import('@/views/query-analyzer/QueryAnalyzer');
  return { default: QueryAnalyzer };
});
const RuleStatus = lazy(async () => {
  const { RuleStatus } = await import('@/views/rule-status/RuleStatus');
  return { default: RuleStatus };
});
const ScopeConfiguration = lazy(async () => {
  const { ScopeConfiguration } = await import('@/views/scope-configuration/ScopeConfiguration');
  return { default: ScopeConfiguration };
});
const ServiceAccounts = lazy(async () => {
  const { ServiceAccounts } = await import('@/views/service-accounts/ServiceAccounts');
  return { default: ServiceAccounts };
});
const ServiceConfiguration = lazy(async () => {
  const { ServiceConfigPage } = await import('@/views/service-configuration/ServiceConfigPage');
  return { default: ServiceConfigPage };
});
const EditComponent = lazy(async () => {
  const { EditPresentation } = await import('@/views/service-configuration/legacy/EditPresentation');
  return { default: EditPresentation };
});
const TeamsTable = lazy(async () => {
  const { TeamsTable } = await import('@/views/teams/TeamsTable');
  return { default: TeamsTable };
});
const Users = lazy(async () => {
  const { ManagingUsers } = await import('@/views/users/ManagingUsers');
  return { default: ManagingUsers };
});
const Logs = lazy(async () => {
  const { Logs } = await import('@/views/logs/Logs');
  return { default: Logs };
});
const LogsUtility = lazy(async () => {
  const { LogsUtility } = await import('@/views/logs/usage-analysis/LogsUtility');
  return { default: LogsUtility };
});
const LogScale = lazy(async () => {
  const { LogScale } = await import('@/views/logscale/LogScale');
  return { default: LogScale };
});
const LogScaleAutomation = lazy(async () => {
  const { LogScaleAutomation } = await import('@/views/logscale/LogScaleAutomation');
  return { default: LogScaleAutomation };
});
const MetricsDDXPage = lazy(async () => {
  const { MetricsDDXView } = await import('@/views/metrics-ddx/MetricsDDXView');
  return { default: MetricsDDXView };
});

// Settings route components
const MyAccount = lazy(async () => {
  const { MyAccount } = await import('@/views/accounts/MyAccount');
  return { default: MyAccount };
});

// Help route components
const Documentation = lazy(async () => {
  const { Documentation } = await import('@/views/Documentation');
  return { default: Documentation };
});
const ReleaseNotes = lazy(async () => {
  const { ReleaseNotes } = await import('@/views/release-notes');
  return { default: ReleaseNotes };
});

// Admin Routes
const DashboardTools = lazy(async () => {
  const { DashboardToolPage } = await import('@/admin/dashboarding');
  return { default: DashboardToolPage };
});

const DashboardToolsConverter = lazy(async () => {
  const { ConvertDebuggerPage } = await import('@/admin/dashboarding');
  return { default: ConvertDebuggerPage };
});

export const RouteConstants = {
  HOME: '/',
  V3: '/v3',
};

export const PublicRoutes = {
  PUBLIC_DASHBOARD: '/public/dashboards/:dashboardSlug',
};

export const AuthRoutes = {
  SIGNUP_INVITE: '/signup-invite',

  LOGIN: '/login',
  LOGIN_CALLBACK: '/login/callback',
  LOGIN_PKCE_CALLBACK: '/login/pkce/callback',
  IMPERSONATE: '/login/impersonate',
  IMPERSONATE_CALLBACK: '/login/impersonate/callback',
  IMPERSONATE_PKCE_CALLBACK: '/login/impersonate/pkce/callback',
  SLACK_OAUTH_CALLBACK: '/login/slack/callback',
  SLACK_OAUTH_SUCCESS: '/login/slack/success',
  RESET_PASSWORD: '/login/reset',
  CLI_LOGIN: '/login/cli',
  CLI_LOGIN_CALLBACK: '/login/cli/callback',
  CLI_IMPERSONATE: '/login/cli/impersonate',
  CLI_IMPERSONATE_CALLBACK: '/login/cli/impersonate/callback',

  LOGOUT: '/logout',
  LOGOUT_CALLBACK: '/logout/callback',
};

// This function maps the route object to add type safety for the route keys
const mapRoutes = <T>(routeObj: {
  [k in keyof T]: RouteObject;
}): MappedRoutes<T> => {
  const mappedRoutes = {} as MappedRoutes<T>;
  for (const route in routeObj) {
    mappedRoutes[route] = routeObj[route];
  }
  return mappedRoutes;
};

export const HomeRoutes = mapRoutes({
  GLOBAL: { route: RouteConstants.HOME, Component: GlobalHome, header: { title: 'Home' } },
  TEAM: {
    route: '/teams/:teamSlug',
    Component: TeamHome,
    header: { title: '', crumbs: [{ name: 'Teams', href: '/teams' }] },
  },
  COLLECTION: {
    route: '/collections/:collectionSlug',
    Component: CollectionHome,
    header: { title: '', crumbs: [{ name: 'Collections', href: '/collections' }], pinnedScope: true },
  },
  LICENSE_OVERVIEW: { route: '/license-overview', Component: LicenseHome, header: { title: 'License Overview' } },
});

export const ShortUrlRoutes = mapRoutes({
  SHORT_URLS: { route: '/go/:hash', Component: ShortUrlLoader, header: { title: 'Short URL' } },
});

export const ServicesRoutes = mapRoutes({
  SERVICES: {
    route: '/services',
    Component: ServicesHome,
    header: { title: 'Services', pinnedScope: true },
  },
  SERVICE_DETAILS: {
    route: '/services/:serviceSlug',
    Component: ServiceOverviewPage,
    header: { title: '', pinnedScope: true, crumbs: [{ name: 'Services', href: '/services' }] },
  },
  SERVICE_COMPONENT_MORE_DETAILS: {
    route: '/lens/components/:serviceComponentSlug',
    Component: ServiceComponentMoreDetailsPage,
    header: { title: 'Service component', pinnedScope: true },
    // Temporarily gating the service overview UI and scope configuration UI to internal
    hideRouteFn: ({ gatewayConfigs, isInternalUser }) => !(isInternalUser || gatewayConfigs.serviceUIEnabled),
  },
  SERVICE_COMPONENT_SANDBOX: {
    route: '/lens/__sandbox',
    Component: ServiceComponentSandbox,
    header: { title: 'Service component', pinnedScope: true },
    // Temporarily gating the service overview UI and scope configuration UI to internal
    hideRouteFn: ({ gatewayConfigs, isInternalUser }) => !(isInternalUser || gatewayConfigs.serviceUIEnabled),
  },
});

export const SloRoutes = mapRoutes({
  SLO_LIST: {
    route: '/slos',
    Component: SloList,
    header: { title: 'SLOs', pageAccess: 'preview', pinnedScope: true },
  },
  SLO_DETAILS: {
    route: '/slos/:sloSlug',
    Component: SloDetails,
    header: { title: '', crumbs: [{ name: 'SLOs', href: '/slos' }], pageAccess: 'preview', pinnedScope: true },
  },
});

export const DASHBOARDS = {
  route: '/dashboards',
  Component: Dashboards,
  header: { title: 'Dashboards', pinnedScope: true },
};

export const DASHBOARD_DETAILS = {
  route: '/dashboards/:dashboardSlug',
  Component: Dashboard,
  header: { title: '', crumbs: [{ name: 'Dashboards', href: '/dashboards' }] },
  wildcard: true,
};

export const DashboardRoutes = mapRoutes({
  DASHBOARDS,
  DASHBOARD_DETAILS,
});

export const AlertingRoutes = mapRoutes({
  MONITORS: {
    route: '/monitors',
    Component: Monitors,
    header: { title: 'Monitors' },
  },
  MONITOR_DETAILS: {
    route: '/monitors/:monitorSlug',
    Component: Monitor,
    header: { title: '', crumbs: [{ name: 'Monitors', href: '/monitors' }] },
  },
  MONITOR_HISTORY: {
    route: '/monitors/:monitorSlug/history',
    Component: Monitor,
    header: { title: '', crumbs: [{ name: 'Monitors', href: '/monitors' }] },
  },
  MONITOR_SIGNALS: {
    route: '/monitors/:monitorSlug/signals',
    Component: Monitor,
    header: { title: '', crumbs: [{ name: 'Monitors', href: '/monitors' }] },
  },
  MONITOR_SIGNALS_HISTORY: {
    route: '/monitors/:monitorSlug/signals/history',
    Component: Monitor,
    header: { title: '', crumbs: [{ name: 'Monitors', href: '/monitors' }] },
  },
  MUTING_RULES: {
    route: '/muting-rules',
    Component: MutingRules,
    header: { title: 'Muting Rules' },
  },
  MUTING_RULE_DETAILS: {
    route: '/muting-rules/:mutingRuleSlug',
    Component: MutingRules,
    header: { title: 'Muting Rules' },
  },
  NOTIFIERS: {
    route: '/notifiers',
    Component: Notifiers,
    header: { title: 'Notifiers' },
  },
  NOTIFIERS_DETAILS: {
    route: '/notifiers/:id',
    Component: Notifiers,
    header: { title: 'Notifiers' },
  },
  NOTIFICATION_POLICIES: {
    route: '/notification-policies',
    Component: NotificationPolicies,
    header: { title: 'Notification Policies' },
  },
  NOTIFICATION_POLICY_DETAILS: {
    route: '/notification-policies/:id',
    Component: NotificationPolicies,
    header: { title: 'Notification Policies' },
  },
});

export const ExploringRoutes = mapRoutes({
  METRICS_EXPLORER: {
    route: '/metrics/explorer',
    Component: MetricsExplorer,
    header: { title: 'Metrics Explorer' },
  },
  METRIC_DDX: {
    route: '/metrics/ddx',
    Component: MetricsDDXPage,
    header: { title: 'Metrics DDx', pinnedScope: false },
    hideRouteFn: ({ featureFlags, gatewayConfigs }) => !featureFlags.metricsDdxPoc && !gatewayConfigs.enableMetricsDDX,
  },
  METRICS_ANALYZER: {
    route: '/metrics/analyzer',
    Component: MetricsAnalyzer,
    header: { title: 'Live Telemetry Analyzer' },
  },
  PROFILER_REDIRECT: {
    route: '/metrics/profiler',
    Component: MetricsAnalyzerRedirect,
    header: { title: '' },
  },
  TRACES_EXPLORER: {
    route: '/traces/explorer',
    Component: Traces,
    header: { title: 'Trace Explorer' },
  },
  TRACE_DDX: {
    route: '/traces/ddx',
    Component: TracesDdx,
    header: { title: 'Trace Explorer' },
  },
  TRACE_TOPOLOGY_VIEW: {
    route: '/traces/topology-view',
    Component: TracesTopologyView,
    header: { title: 'Trace Explorer' },
  },
  TRACES_ANALYZER: {
    route: '/traces/analyzer',
    Component: TraceAnalyzer,
    header: { title: 'Live Telemetry Analyzer' },
  },
  TRACE_METRICS: {
    route: '/traces/metrics',
    Component: TraceMetrics,
    header: { title: 'Trace Metrics' },
  },
  TRACE_DETAILS: {
    route: '/traces/trace-detail/:traceId',
    Component: TraceDetails,
    header: { title: '' },
  },
  TRACE_USER_JOURNEYS: {
    route: '/traces/user-journeys',
    Component: TraceUserJourneys,
    header: { title: 'User Journeys', pageAccess: 'preview' },
  },
  CHANGES_EXPLORER: {
    route: '/events/explorer',
    Component: Timeline,
    header: { title: 'Changes Explorer' },
  },
  USAGE_PROFILER: {
    route: '/metrics/usage',
    Component: UsageAnalyzer,
    header: { title: 'Telemetry Usage Analyzer' },
  },
  BETA_EXPLORER: {
    route: '/metrics/explorer-v2',
    Component: ExplorerBeta,
    header: { title: 'Metrics Explorer', pinnedScope: true },
  },
  LOGS_EXPLORER: {
    route: '/logs/explorer',
    Component: Logs,
    header: { title: 'Logs Explorer' },
  },
  LOGS_UTILITY: {
    route: '/logs/utility',
    Component: LogsUtility,
    header: { title: 'Logs Utility' },
  },
  LOGSCALE: {
    route: '/logs-explorer',
    Component: LogScale,
    header: { title: 'Logs Explorer' },
  },
  LOGSCALE_AUTOMATION: {
    route: '/logs-automation',
    Component: LogScaleAutomation,
    header: { title: 'Logs Automation' },
  },
});

export const ShapingRoutes = mapRoutes({
  DROP_RULES: {
    route: '/drop-rules',
    Component: DropRules,
    header: { title: 'Drop Rules' },
  },
  DROP_RULES_DETAILS: {
    route: '/drop-rules/:dropRuleSlug',
    Component: DropRules,
    header: { title: 'Drop Rules' },
  },
  INGEST_CONFIG: {
    route: '/ingest-config',
    Component: IngestConfiguration,
    header: { title: 'Ingest Configuration' },
  },
  RECORDING_RULES: {
    route: '/recording-rules',
    Component: RecordingRules,
    header: { title: 'Recording Rules' },
  },
  AGGREGATION_RULES: {
    route: '/aggregation-rules',
    Component: AggregationRules,
    header: { title: 'Aggregation Rules' },
  },
  TRACE_CONTROL_PLANE: {
    route: '/trace-control-plane',
    Component: TraceDatasetsOverview,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_HEAD_SAMPLING: {
    route: '/trace-control-plane/head-sampling',
    Component: TraceHeadSamplingRules,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_TAIL_SAMPLING: {
    route: '/trace-control-plane/tail-sampling',
    Component: TraceTailSamplingRules,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_DATASETS_OVERVIEW: {
    route: '/trace-control-plane/datasets',
    Component: TraceDatasetsOverview,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_DATASETS_DETAIL: {
    route: '/trace-control-plane/datasets/:id',
    Component: TraceDatasetsDetail,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_BEHAVIORS: {
    route: '/trace-control-plane/behaviors',
    Component: TraceBehaviors,
    header: { title: 'Trace Control Plane' },
  },
  TRACE_BEHAVIORS_DETAIL: {
    route: '/trace-control-plane/behaviors/:id',
    Component: TraceBehaviorDetails,
    header: { title: 'Trace Control Plane' },
  },
});

export const ManagingRoutes = mapRoutes({
  AUDIT_LOGS: {
    route: '/audit-logs',
    Component: AuditLogs,
    header: { title: 'Audit Logs' },
  },
  AUDIT_LOGS_DETAILS: {
    route: '/audit-logs/:logId',
    Component: AuditLogs,
    header: { title: 'Audit Logs' },
  },
  COLLECTIONS: {
    route: '/collections',
    Component: CollectionsTable,
    header: { title: 'Collections', pinnedScope: true },
  },
  DERIVED_LABELS: {
    route: '/derived-labels',
    Component: DerivedLabelsRedirect,
    header: { title: 'Derived Labels' },
    hideRouteFn: ({ featureFlags }) => !featureFlags.derivedLabelsUi,
  },
  PUBLISHED_DERIVED_LABELS: {
    route: '/derived-labels/published',
    Component: PublishedDerivedLabels,
    header: { title: 'Derived Labels' },
    hideRouteFn: ({ featureFlags }) => !featureFlags.derivedLabelsUi,
  },
  DRAFT_DERIVED_LABELS: {
    route: '/derived-labels/draft',
    Component: DraftDerivedLabels,
    header: { title: 'Derived Labels' },
    hideRouteFn: ({ featureFlags }) => !featureFlags.derivedLabelsUi,
  },
  RULE_STATUS: {
    route: '/rule-status',
    Component: RuleStatus,
    header: { title: 'Rule Status' },
  },
  SCOPE_CONFIGURATION: {
    route: '/scope-configuration',
    Component: ScopeConfiguration,
    header: { title: 'Scope Configuration', pageAccess: 'internal' },
    // Temporarily gating the service overview UI and scope configuration UI to internal
    hideRouteFn: ({ gatewayConfigs, isInternalUser }) => !(isInternalUser || gatewayConfigs.serviceUIEnabled),
  },
  SERVICE_ACCOUNTS: {
    route: '/service-accounts',
    Component: ServiceAccounts,
    header: { title: 'Service Accounts' },
  },
  SERVICE_CONFIG: {
    route: '/service-configuration',
    Component: ServiceConfiguration,
    header: { title: 'Service Configuration' },
    // Temporarily gating the service configuration UI to internal users
    // or tenants where we have flipped the flag to allow them to use it
    hideRouteFn: ({ gatewayConfigs, isInternalUser }) =>
      !gatewayConfigs.serviceUIEnabled || !(isInternalUser || gatewayConfigs.enableExternalServiceConfig),
  },
  SERVICE_CONFIG_EDIT_COMPONENT: {
    route: '/service-configuration/edit-component',
    Component: EditComponent,
    header: { title: '', crumbs: [{ name: 'Service Configuration', href: '/service-configuration' }] },
    // Temporarily gating the edit presentation UI to internal users only
    hideRouteFn: ({ gatewayConfigs, isInternalUser }) => !(gatewayConfigs.serviceUIEnabled && isInternalUser),
  },
  SERVICE_CONFIG_EDIT_COMPONENT_DETAILS: {
    route: '/service-configuration/edit-component/:componentSlug',
    Component: EditComponent,
    header: {
      title: '',
      pageAccess: 'internal',
      crumbs: [{ name: 'Service Configuration', href: '/service-configuration' }],
    },
  },
  TEAMS: {
    route: '/teams',
    Component: TeamsTable,
    header: { title: 'Teams' },
  },
  USERS: {
    route: '/users',
    Component: Users,
    header: { title: 'Users' },
  },
  USER_DETAILS: {
    route: '/users/:id',
    Component: Users,
    header: { title: 'Users' },
  },
  ALLOCATIONS: {
    route: '/allocations',
    subRoutes: ['pools', 'manage-allocations'],
    Component: MetricsAllocations,
    header: { title: 'Metrics Allocations' },
    wildcard: true,
  },
  QUOTAS: {
    route: '/quotas/pools',
    Component: MetricsQuotas,
    header: { title: 'Metrics Quotas' },
  },
  QUOTAS_DETAILS: {
    route: '/quotas/pools/:id',
    Component: MetricsQuotas,
    header: { title: 'Metrics Quotas' },
  },
  QUOTAS_CONFIG: {
    route: '/quotas/config',
    Component: MetricsQuotas,
    header: { title: 'Configure Quotas', crumbs: [{ name: 'Metrics Quotas', href: '/quotas/pools/' }] },
  },
  QUERY_ANALYZER: {
    route: '/query-analyzer',
    Component: QueryAnalyzer,
    header: { title: 'Query Analyzer' },
  },
});

export const SettingsRoutes = mapRoutes({
  MY_ACCOUNT: { route: '/account', Component: MyAccount, header: { title: 'My Account' } },
});

export const HelpRoutes = mapRoutes({
  DOCUMENTATION: {
    route: '/documentation',
    Component: Documentation,
    header: { title: 'Documentation' },
    wildcard: true,
  },
  RELEASE_NOTES: { route: '/release-notes', Component: ReleaseNotes, header: { title: 'Release notes' } },
});

// These routes are only accessible to internal users
export const ChronoAdminRoutes = mapRoutes({
  DASHBOARD_TOOLS: {
    route: '/__admin/dashboard-tools',
    Component: DashboardTools,
    header: { title: 'Dashboard Tools' },
  },
  DASHBOARD_TOOLS_CONVERTER: {
    route: '/__admin/dashboard-tools/convert',
    Component: DashboardToolsConverter,
    header: { title: 'Dashboard Tools - Grafana Converter' },
  },
});
